import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    scanTypes: ["VSID"],
    notificationSidebar: {
      badgeValue: false,
      isOpen: false,
      isLoading: false,
      notifs: []
    },
    id: null,
    user: null,
    darkTheme: null,
    allowedBranches: [],
    GLOBALS: [],
    quickList: {},
    updateCounter: 0,
    perms: null,
    endpoint: null,
    workingId: null,
    currentRegister: null,
    globalQueryBc: true,
    userCache: [],
    branches: [],
    scanBus: {
      data: null,
      handler: 'global',
    },
    serialNaming: {
      singular: "Serial",
      singularLower: "serial",
      plural: "Serials",
      pluralLower: "serials",
    },
    isManaged: null,
    locations: [],
    paymentTypes: [],
    taxTypes: [],
    paymentMethods: [],
    menuItems: [
      { icon: 'mdi-home', text: 'Home', path: '/', protected: false, filterIdentifier: 'HOME'},
      { icon: 'mdi-link-lock', text: 'Supply Chain', path: '/suppliers', protected: false, filterIdentifier: 'SUPPLIERS'},
      { icon: 'mdi-devices', text: 'Devices', path: '/devices', protected: false, filterIdentifier: 'DEVICES'},
      { icon: 'mdi-store', text: 'Locations', path: '/locations', protected: false, filterIdentifier: 'LOCATION'},
      { icon: 'mdi-account-group', text: 'Customers', path: '/customers', protected: false, filterIdentifier: 'CUSTOMERS'},
      { icon: 'mdi-account-circle', text: 'User Management', path: '/users', protected: false, filterIdentifier: 'USERMANAGEMENT'},
      { icon: 'mdi-sale', text: 'Promotions', path: '/promotions', protected: false, filterIdentifier: 'PROMOTIONS'},
      { icon: 'mdi-currency-usd', text: 'Orders', path: '/orders', protected: false, filterIdentifier: 'ORDERS'},
      { icon: 'mdi-bank-transfer', text: 'Inventory Transfer', path: '/inventoryTransfers', protected: false, filterIdentifier: 'INVENTORYTRANSFER'},
      { icon: 'mdi-truck-delivery-outline', text: 'Dispatch', path: '/dispatch', protected: false, filterIdentifier: 'DISPATCH'},
      { icon: 'mdi-package', text: 'Product Manager', path: '/products', protected: false, filterIdentifier: 'PRODUCTMANAGER'},
      { icon: 'mdi-tally-mark-5', text: 'Stocktaking Events', path: '/stocktakingEvents', protected: false, filterIdentifier: 'STOCKTAKING_ADMIN'},
      { icon: 'mdi-truck-plus', text: 'Purchasing', path: '/purchasing', protected: false, filterIdentifier: 'PURCHASING'},
      { icon: 'mdi-package-variant-closed-plus', text: 'Receiving', path: '/receiving', protected: false, filterIdentifier: 'RECEIVING'},
      { icon: 'mdi-redo', text: 'Returns', path: '/returns', protected: false, filterIdentifier: 'RETURNS'},
      { icon: 'mdi-timer-check', text: 'Hire Purchase', path: '/hirepurchase', protected: false, filterIdentifier: 'HIREPURCHASE'},
      { icon: 'mdi-chart-bar', text: 'Reporting', path: '/reporting', protected: false, filterIdentifier: 'REPORTING'},
      { icon: 'mdi-text-box-search', text: 'Logs', path: '/logs', protected: false, filterIdentifier: 'LOGS'},
      { icon: 'mdi-close-octagon', text: 'Super Admin', path: '/superadmin', protected: false, filterIdentifier: 'SUPER'},
    ],
    activateScanSensePing: false,
    envConstants: null,
  },
  mutations: {
    setUser(state, user){
      localStorage.setItem('id', user.id)
      state.user = user
      state.id = user.id
      state.allowedBranches = state.user.metadata.branchData.allowed.map(x => {
        return state.branches.find(n => x===n.id);
      });
    },
    toggleTheme(state, val){
      state.darkTheme = val
      localStorage.setItem('darkTheme', val)
    },
    setUserCache(state, users){
      state.userCache = users
      console.log(state.userCache.length > 0 ? "VUEX: ✅ User Cache loaded." : "VUEX: ❌ User Cache not loaded.")
    },
    setQuickList(state, ql){
      console.log(ql)
      state.quickList = ql
      console.log(Object.keys(state.quickList).length > 0 ? "VUEX: ✅ Quick List loaded." : "VUEX: ❌ Quick List not loaded.")
    },
    setBranches(state, branches){
      state.branches = branches
      console.log(state.branches ? "VUEX: ✅ Branches loaded." : "VUEX: ❌ Branches not loaded.")
    },
    setNotificationBadge(state, value){
      state.notificationSidebar.badgeValue = value
    },
    setLocations(state, locations){
      state.locations = locations
      console.log(state.locations ? "VUEX: ✅ Locations loaded." : "VUEX: ❌ Locations not loaded.")
    },
    setPaymentMethods(state, paymentMethods){
      state.paymentMethods = paymentMethods
      console.log(state.paymentMethods.length > 0 ? "VUEX: ✅ Payment Methods Cache loaded." : "VUEX: ❌ Payment Methods Cache not loaded.")
    },
    setTaxTypes(state, taxTypes){
      state.taxTypes = taxTypes
      console.log(state.taxTypes.length > 0 ? "VUEX: ✅ Tax Types Cache loaded." : "VUEX: ❌ Tax Types Cache not loaded.")
    },
    setPaymentTypes(state, paymentTypes){
      state.paymentTypes = paymentTypes
      console.log(state.paymentTypes.length > 0 ? "VUEX: ✅ Payment Types Cache loaded." : "VUEX: ❌ Payment Types Cache not loaded.")
    },
    setBranch(state, branch){
      state.branch = branch
    },
    setNotifications(state, notifs){
      state.notificationSidebar.notifs = notifs
      console.log(state.notificationSidebar.notifs.length > 0 ? "VUEX: ✅ Notifications loaded." : "VUEX: ❌ Notifications not loaded.")
    },
    openNotifications(state){
      state.notificationSidebar.isOpen = true
      state.notificationSidebar.badgeValue = false
    },
    closeNotifications(state){
      state.notificationSidebar.isOpen = false
    },
    clearUserCache(state){
      state.userCache = []
    },
    async loadPerms(state){
      try{
        let perms = await axios.get(`${state.endpoint}/getPerms`)
        if(perms.data.error) throw "Error fetching permission data."
        state.perms = perms.data;
      }
      catch (e) {
        console.error(e);
      }
    },
    setPerms(state, perms){
      state.perms = perms;
    },
    setEndpoint(state, endpoint){
      state.endpoint = endpoint;
    },
    updateWorkingId(state, wId){
      localStorage.setItem('workingId', wId)
      state.workingId = wId;
    },
    deleteWorkingId(state){
      localStorage.removeItem('workingId')
      state.workingId = null;
    },
    updateCurrentRegister(state, uuid){
      state.currentRegister = uuid
    },
    lockGlobalQueryBc(state){
      state.globalQueryBc = false
      console.log("Global Barcode Listener Locked")
    },
    unlockGlobalQueryBc(state){
      state.globalQueryBc = true
      console.log("Global Barcode Listener Unlocked")
    },
    initialiseStore(state) {
      if (localStorage.getItem('id')) {
        state.id = localStorage.getItem('id');
        state.workingId = localStorage.getItem('workingId');
      }
      state.darkTheme = localStorage.getItem('darkTheme')==="true";
    },
    logout(state){
      localStorage.removeItem('id')
      localStorage.setItem('workingId', null)
      state.id = null;
      state.workingId = null;
      state.perms = null;
      state.quickList = null;
      state.user = null
    },
    removeIds(state){
      state.id = null;
      state.userName = null;
      state.user = null
    },
    setScanBus(state, bus){ //{handler: null, data:null}
      if(bus.data){
        state.scanBus.data = bus.data
      }
      if(bus.handler){
        state.scanBus.handler = bus.handler
      }
    },
    resetScanBus(state){
      state.scanBus.handler = 'global'
      state.scanBus.data = null
    },
    setManaged(state, val){
      state.isManaged = val;
    },
    setGLOBALS(state, val){
      state.GLOBALS = val;
      console.log("VUEX: ✅ DB Globals Set.")
    },
    setEnvConstants(state, val){
      state.envConstants = val;
    },
    activateScanSense(state){
      state.activateScanSensePing = true
    },
    forceUpdateCounter(state){
      state.updateCounter++
    },
    setSerialNaming(state, val){
      state.serialNaming = val;
    },
  },
  actions: {    
  },
  modules: {
  },
  getters: {
    getId: state => state.id,
    getEndpoint: state => state.endpoint,
    getWorkingId: state => state.workingId,
    getCurrentRegister: state => state.currentRegister,
    getPerms: state => state.perms,
    getUserCache: state => state.userCache,
    lookupUsername: (state) => (id) =>{
      if(id==0) return "System"
      if(!id) return null
      let y = state.userCache.find(x=>x.id==id)
      if(!y) return null
      return y.firstName + ' ' + (y.lastName?y.lastName:'')
    },
    getBranch: state => state.user?.metadata?.branchData?.main,
    getBranches: state => state.branches,
    lookupLocation: (state) => (id) =>{
      if(!id) return null
      let y = state.locations.find(x=>x.id==id)
      if(!y) return null

      let nameStr = '';
      let curr = y;
      while(curr.parent!==null){
        nameStr='>'+curr.name+nameStr;
        curr = state.locations.find(x=>x.id==curr.parent)
      }
      nameStr=curr.name+nameStr;
      return nameStr;
    },
    getAllowedBranches: state => state.allowedBranches,
    lookupBranch: (state) => (id) =>{
      if(!id) return null
      let y = state.branches.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    lookupPaymentType: (state) => (id) =>{
      if(!id) return null
      let y = state.paymentTypes.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    lookupTaxType: (state) => (id,abbreviated) =>{
      if(!id) return null
      let y = state.taxTypes.find(x=>x.id==id)
      if(!y) return null
      if(abbreviated) return y.name.split(' ').map(x=>x.charAt(0)).join('').toUpperCase()
      return y.name;
    },
    lookupPaymentTypeId: (state) => (name) =>{
      if(!name) return null
      let y = state.paymentTypes.find(x=>x.name==name)
      if(!y) return null
      return y.id;
    },
    lookupPaymentMethod: (state) => (id) =>{
      if(!id) return null
      let y = state.paymentMethods.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    getBranchData: (state) => (id) =>{
      if(!id) return null
      let y = state.branches.find(x=>x.id==id)
      if(!y) return null
      return y;
    },
    getUser: state => state.user,
    isLoggedIn: state => state.id ? true : false,
    globalQueryBcAllowed: (state)=>{
      return state.globalQueryBc
    },
    isAllowed: (state) => (resource, requiredPerm) => {
      if(!resource) return false
      if(!requiredPerm) return false
      if(!state.perms) return false
      return (state.perms.permissions[resource])[requiredPerm]
    },
    getRole: state => state.perms?.description,
    nowServing: state => state.sIDAuthorizer.nowServing,
    scanBus: state => state.scanBus,
    isManaged: state => state.isManaged,
    menuItems: (state) => {
      let allowedMenuItems = state.GLOBALS.find(x=>x.key=="VEC_MENU_ITEMS")?.value.split("\n")
      if(!allowedMenuItems) return
      let x = state.menuItems.filter(x=>(allowedMenuItems.find(y=>y==x.filterIdentifier)!=undefined)&&state?.perms?.permissions.allowedMenuItems[x.filterIdentifier]==true)
      return x
    },
    paymentMethods: state => state.paymentMethods,
    paymentTypes: state => state.paymentTypes,
    taxTypes: state => state.taxTypes,
    activateScanSensePing: state => state.activateScanSensePing,
    getGlobalValue: (state) => (key) => (state.GLOBALS.find(x=>x.key==key))?.value,
    envConstants: state => state.envConstants,
    getQuickList: state => state.quickList,
    getUpdateCounter: state => state.updateCounter,
    notificationSidebar: state => state.notificationSidebar,
    serialNaming: state => state.serialNaming,
    darkTheme: state => state.darkTheme,
  }
})
